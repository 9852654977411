import React, { useState } from 'react';
import { useLocation } from '@reach/router';
import classNames from 'classnames';

import MobileHamburgerMenu from '@components/HamburgerMenu';
import HeaderLogo from '../HeaderLogo';
import DesktopLeftLinks from '../DesktopLeftLinks';
import DesktopRightLinks from '../DesktopRightLinks';

import includes from 'lodash/includes';
const Header = () => {
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);

  const { pathname } = useLocation();

  const handleHamburgerMenuChange = (event) => {
    setIsHamburgerMenuOpen(event.target.checked);
  };

  const isMobilePrivacyPolicy = includes(pathname, 'mobile-privacy-policy');

  return (
    <header className="header-section">
      <div
        className={classNames('header-container', {
          'header-fixed': isHamburgerMenuOpen,
        })}
      >
        <div
          className={classNames('header', {
            '-align-left': isMobilePrivacyPolicy,
          })}
        >
          <div className="left-menu">
            <HeaderLogo />
            {!isMobilePrivacyPolicy && (
              <div className="large-menu-items">
                <DesktopLeftLinks />
              </div>
            )}
          </div>
          {!isMobilePrivacyPolicy && (
            <div className="right-menu">
              <div className="small-menu-items">
                <MobileHamburgerMenu onChange={handleHamburgerMenuChange} />
              </div>
              <div className="large-menu-items">
                <DesktopRightLinks />
              </div>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
