import React from 'react';
import Layout from '@components/Layout/LayoutTemplates/JobSeekersLayout';
import SEO from '@components/SEO';
import FAQsResultsModule from '@modules/FAQs/Results';

const FAQsResultPage = () => {
  return (
    <Layout isReleased={true} isMobileComingSoon={false}>
      <SEO title="FAQs Results" description="FAQs Results" />
      <div className="faqs-results-section">
        <FAQsResultsModule />
      </div>
    </Layout>
  );
};

export default FAQsResultPage;
