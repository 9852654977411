import React from 'react';

const EmptyState = () => (
  <div className="empty-section">
    <h1>Coming Soon.</h1>
    <h2>We're launching soon, Please comeback later.</h2>
  </div>
);

export default EmptyState;
